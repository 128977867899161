<template>
  <div class="layout-subcontainer">
    <div class="tab_contents_wrap">
      <table>
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col width="100px" />
        </colgroup>
        <thead>
          <th>detph1</th>
          <th>detph2</th>
          <th>detph3</th>
          <th>path</th>
          <th>etc</th>
        </thead>
        <tbody>
          <tr
            v-for="item in items"
            v-html="item.data"
            link
            @click="mixin_set_active_tab(item)"
            style="cursor:pointer"
          >
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  mixins: [mixin],
  methods: {
    closePop() {
      console.log("closePop click");
      this.$store.dispatch("commonStore/AC_COMMON_HELP_DIALOG", {
        type: false,
      });
    },
  },
  computed: {
    ...mapGetters({
      user_name: "userStore/GE_USER_NAME",
      user_id: "userStore/GE_USER_ID",
      INLNE_NO: "userStore/GE_USER_INLNE_NO",
      user_menu: "userStore/GE_USER_MENU",
      user_role: "userStore/GE_USER_ROLE",
    }),
  },
  mounted() {
    this.init()
  },
  data() {
    return {
      items: [
 
        {
          C_NODE_NO: "CAM0400",
          NODE_TITLE: "개인상담관리",
          class: "",
          data: "<td class='depth1'></td>"+
                "<td class='depth2'>캠페인</td>"+
                "<td class='depth3'>개인상담관리</td>"+
                "<td class='page'>CAM0400</td>"
        },
        {
          C_NODE_NO: "CAM0500",
          NODE_TITLE: "현장관리",
          class: "",
          data: "<td class='depth1'></td>"+
                "<td class='depth2'>캠페인</td>"+
                "<td class='depth3'>현장관리</td>"+
                "<td class='page'>CAM0500</td>"
        },
      ],
      right: null,
    };
  },

  methods: {
    init() {
      if(this.user_role.atrtGroupNm==='상담사'){
        this.items=[{
          C_NODE_NO: "CAM0400",
          NODE_TITLE: "개인상담관리",
          class: "",
          data: "<td class='depth1'></td>"+
                "<td class='depth2'>캠페인</td>"+
                "<td class='depth3'>개인상담관리</td>"+
                "<td class='page'>CAM0400</td>"
        }]
      }else if(this.user_role.atrtGroupNm==='현장관리자'){
        this.items=[{
          C_NODE_NO: "CAM0500",
          NODE_TITLE: "현장관리",
          class: "",
          data: "<td class='depth1'></td>"+
                "<td class='depth2'>캠페인</td>"+
                "<td class='depth3'>현장관리</td>"+
                "<td class='page'>CAM0500</td>"
        }]
      }

    },
  }
};

</script>

<style scoped>
.tab_contents_wrap {
  width: 100%;
  margin-top: 10px;
  border-top: none;
}
.tab_contents_wrap table {
  width: 100%;
  background-color: #fff;
}
.tab_contents_wrap table th {
  background: #e3e3e3;
  border: 1px solid #eee;
}
.tab_contents_wrap table td {
  border: 1px solid #eee;
}
.tab_contents_wrap table tr td:first-child {
  position: relative;
}
.tab_contents_wrap table tr:hover td {
  background-color: #dcf1ff;
}
.tab_contents_wrap table tr.focus:hover td {
  background-color: #f7e4e4;
}
.tab_contents_wrap table td.etc {
  position: relative;
  padding-right: 15px;
}
.tab_contents_wrap table td.etc .moreBtn {
  position: absolute;
  top: 3px;
  right: 5px;
  color: #ccc;
}
.tab_contents_wrap table td.etc .moreBtn.on {
  right: 5px;
  opacity: 1;
  transition: all ease-in-out 0.26s;
}
.tab_contents_wrap table td.etc .moreBtn.on:hover {
  color: #666;
}
</style>
